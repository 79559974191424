const $ = $jq183;

function smoothScr() {

  $('a[href^="#"]').click(function(){
    let adjust = $('.l-header').outerHeight();
    let speed = 600;
    let href= $(this).attr("href");
    let target = $(href == "#" || href == "" ? 'html' : href);
    let position = target.offset().top - adjust;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

  

}

function imgChange(pc,sp){
    function changeImg(bfr,aft){
        $('.change-img').each(function(index, element) {
          let replaceImg = $(this).attr('src').replace(bfr,aft);
          $(this).attr('src', replaceImg);
        });
      }
  
      if (sp) {
        changeImg('_pc','_sp');      
      } else if (pc) {
        changeImg('_sp','_pc');
      }
}

$(function(){
  smoothScr();
});

$(window).on('load resize', function(){
    let sp = window.matchMedia('(max-width: 1079px)').matches;
    let pc = window.matchMedia('(min-width: 1080px)').matches;
    imgChange(pc,sp);
});
